// // import React, {useEffect, useState} from 'react';
// // import posthog from 'posthog-js';

// // const WorksheetForm = ({onSubmit, initialData, isLoading}) => {
// //   const [formData, setFormData] = useState({
// //     topic: '',
// //     subject: 'English',
// //     gradeLevel: '5',
// //     numberOfQuestions: 5,
// //     questionTypes: ['mcq']
// //   });

// //   // Add check for form completion
// //   const isFormComplete = formData.topic.trim() !== '' &&
// //     formData.subject &&
// //     formData.gradeLevel &&
// //     formData.numberOfQuestions &&
// //     formData.questionTypes.length > 0;

// //   useEffect(() => {
// //     if (initialData) {
// //       setFormData(prevState => ({
// //         ...prevState,
// //         ...initialData,
// //         questionTypes: initialData.questionTypes || ['mcq']
// //       }));
// //     }
// //   }, [initialData]);

// //   const handleInputChange = (e) => {
// //     const {name, value} = e.target;
// //     setFormData(prevState => ({
// //       ...prevState,
// //       [name]: value
// //     }));
// //   };

// //   const handleQuestionTypeChange = (e) => {
// //     const {value, checked} = e.target;
// //     setFormData(prevState => {
// //       let newTypes = checked
// //         ? [...prevState.questionTypes, value]
// //         : prevState.questionTypes.filter(type => type !== value);

// //       if (newTypes.length === 0) {
// //         newTypes = ['mcq'];
// //       }

// //       posthog.capture(checked ? 'question_type_added' : 'question_type_removed', {
// //         action: checked ? 'added' : 'removed',
// //         questionType: value,
// //         totalTypesSelected: newTypes.length,
// //         currentTypes: newTypes,
// //         previousTypes: prevState.questionTypes
// //       });

// //       return {
// //         ...prevState,
// //         questionTypes: newTypes
// //       };
// //     });
// //   };

// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     onSubmit(formData);
// //   };

// //   const inputClasses = "w-full px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] focus:outline-none focus:ring-2 focus:ring-blue-500";
// //   const labelClasses = "block text-sm font-medium text-gray-900 mb-2";

// //   // Dynamic button classes based on form completion
// //   const buttonClasses = `w-full py-3 rounded-lg font-medium transition-colors ${
// //     isLoading
// //       ? 'opacity-50 cursor-not-allowed'
// //       : isFormComplete
// //         ? 'bg-[#6B65E0] text-white hover:bg-[#5952d3]'
// //         : 'bg-gray-200 text-gray-500 hover:bg-gray-300'
// //   }`;

// //   return (
// //     <form onSubmit={handleSubmit} className="space-y-6">
// //       <div>
// //         <label htmlFor="topic" className={labelClasses}>Topic</label>
// //         <input
// //           type="text"
// //           id="topic"
// //           name="topic"
// //           value={formData.topic}
// //           onChange={handleInputChange}
// //           className={inputClasses}
// //           required
// //           placeholder="e.g., Figures of speech"
// //         />
// //       </div>

// //       <div>
// //         <label htmlFor="subject" className={labelClasses}>Subject</label>
// //         <select
// //           id="subject"
// //           name="subject"
// //           value={formData.subject}
// //           onChange={handleInputChange}
// //           className={inputClasses}
// //           required
// //         >
// //           <option value="English">English</option>
// //           <option value="Mathematics">Mathematics</option>
// //           <option value="Science">Science</option>
// //           <option value="History">History</option>
// //           <option value="Geography">Geography</option>
// //           <option value="Literature">Literature</option>
// //           <option value="Physics">Physics</option>
// //           <option value="Chemistry">Chemistry</option>
// //           <option value="Biology">Biology</option>
// //         </select>
// //       </div>

// //       <div className="grid grid-cols-2 gap-4">
// //         <div>
// //           <label htmlFor="gradeLevel" className={labelClasses}>Grade</label>
// //           <select
// //             id="gradeLevel"
// //             name="gradeLevel"
// //             value={formData.gradeLevel}
// //             onChange={handleInputChange}
// //             className={inputClasses}
// //             required
// //           >
// //             {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
// //               <option key={grade} value={grade.toString()}>
// //                 {grade}{['1', '2', '3'].includes(grade.toString()) ? ['st', 'nd', 'rd'][grade - 1] : 'th'}
// //               </option>
// //             ))}
// //           </select>
// //         </div>

// //         <div>
// //           <label htmlFor="numberOfQuestions" className={labelClasses}>Questions</label>
// //           <input
// //             type="number"
// //             id="numberOfQuestions"
// //             name="numberOfQuestions"
// //             value={formData.numberOfQuestions}
// //             onChange={handleInputChange}
// //             className={inputClasses}
// //             min="1"
// //             max="50"
// //             required
// //           />
// //         </div>
// //       </div>

// //       <div>
// //         <label className={labelClasses}>Question Types</label>
// //         <div className="grid grid-cols-2 gap-4">
// //           {[
// //             {value: 'mcq', label: 'Multiple choice'},
// //             {value: 'fillInBlanks', label: 'Fill in the blanks'},
// //             {value: 'trueOrFalse', label: 'True/False'},
// //             {value: 'openEnded', label: 'Open Ended'}
// //           ].map(({value, label}) => (
// //             <label key={value} className="flex items-center">
// //               <input
// //                 type="checkbox"
// //                 value={value}
// //                 checked={formData.questionTypes.includes(value)}
// //                 onChange={handleQuestionTypeChange}
// //                 className="rounded-sm border-[#E5E5E5] text-black focus:ring-0 focus:ring-offset-0"
// //               />
// //               <span className="ml-2 text-sm text-gray-900">{label}</span>
// //             </label>
// //           ))}
// //         </div>
// //       </div>

// //       <button
// //         type="submit"
// //         disabled={isLoading}
// //         className={buttonClasses}
// //       >
// //         {isLoading ? 'Generating...' : 'Generate text worksheet'}
// //       </button>
// //     </form>
// //   );
// // };

// // export default WorksheetForm;


// import React, { useEffect, useState } from 'react';
// import posthog from 'posthog-js';

// const WorksheetForm = ({ onSubmit, initialData, isLoading, isAuthenticated, onAuthRequired }) => {
//   // Existing form state management
//   const [formData, setFormData] = useState({
//     topic: '',
//     subject: 'English',
//     gradeLevel: '5',
//     numberOfQuestions: 5,
//     questionTypes: ['mcq']
//   });

//   // Form completion check remains the same
//   const isFormComplete = formData.topic.trim() !== '' &&
//     formData.subject &&
//     formData.gradeLevel &&
//     formData.numberOfQuestions &&
//     formData.questionTypes.length > 0;

//   // Initialize form with initial data if provided
//   useEffect(() => {
//     if (initialData) {
//       setFormData(prevState => ({
//         ...prevState,
//         ...initialData,
//         questionTypes: initialData.questionTypes || ['mcq']
//       }));
//     }
//   }, [initialData]);

//   // Existing input change handler
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   // Existing question type change handler
//   const handleQuestionTypeChange = (e) => {
//     const { value, checked } = e.target;
//     setFormData(prevState => {
//       let newTypes = checked
//         ? [...prevState.questionTypes, value]
//         : prevState.questionTypes.filter(type => type !== value);

//       if (newTypes.length === 0) {
//         newTypes = ['mcq'];
//       }

//       posthog.capture(checked ? 'question_type_added' : 'question_type_removed', {
//         action: checked ? 'added' : 'removed',
//         questionType: value,
//         totalTypesSelected: newTypes.length,
//         currentTypes: newTypes,
//         previousTypes: prevState.questionTypes
//       });

//       return {
//         ...prevState,
//         questionTypes: newTypes
//       };
//     });
//   };

//   // Enhanced submit handler with authentication check
//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     // If user is not authenticated, trigger auth flow instead of form submission
//     if (!isAuthenticated) {
//       onAuthRequired();
//       return;
//     }
    
//     onSubmit(formData);
//   };

//   // Existing style classes remain unchanged
//   const inputClasses = "w-full px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] focus:outline-none focus:ring-2 focus:ring-blue-500";
//   const labelClasses = "block text-sm font-medium text-gray-900 mb-2";

//   // Button classes now consider authentication state
//   const buttonClasses = `w-full py-3 rounded-lg font-medium transition-colors ${
//     isLoading
//       ? 'opacity-50 cursor-not-allowed'
//       : isFormComplete
//         ? 'bg-[#6B65E0] text-white hover:bg-[#5952d3]'
//         : 'bg-gray-200 text-gray-500 hover:bg-gray-300'
//   }`;

//   // Get appropriate button text based on authentication and loading state
//   const getButtonText = () => {
//     if (isLoading) return 'Generating...';
//     if (!isAuthenticated) return 'Register to create free worksheets';
//     return 'Generate text worksheet';
//   };

//   return (
//     <form onSubmit={handleSubmit} className="space-y-6">
//       <div>
//         <label htmlFor="topic" className={labelClasses}>Topic</label>
//         <input
//           type="text"
//           id="topic"
//           name="topic"
//           value={formData.topic}
//           onChange={handleInputChange}
//           className={inputClasses}
//           required
//           placeholder="e.g., Figures of speech"
//         />
//       </div>

//       <div>
//         <label htmlFor="subject" className={labelClasses}>Subject</label>
//         <select
//           id="subject"
//           name="subject"
//           value={formData.subject}
//           onChange={handleInputChange}
//           className={inputClasses}
//           required
//         >
//           <option value="English">English</option>
//           <option value="Mathematics">Mathematics</option>
//           <option value="Science">Science</option>
//           <option value="History">History</option>
//           <option value="Geography">Geography</option>
//           <option value="Literature">Literature</option>
//           <option value="Physics">Physics</option>
//           <option value="Chemistry">Chemistry</option>
//           <option value="Biology">Biology</option>
//         </select>
//       </div>

//       <div className="grid grid-cols-2 gap-4">
//         <div>
//           <label htmlFor="gradeLevel" className={labelClasses}>Grade</label>
//           <select
//             id="gradeLevel"
//             name="gradeLevel"
//             value={formData.gradeLevel}
//             onChange={handleInputChange}
//             className={inputClasses}
//             required
//           >
//             {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
//               <option key={grade} value={grade.toString()}>
//                 {grade}{['1', '2', '3'].includes(grade.toString()) ? ['st', 'nd', 'rd'][grade - 1] : 'th'}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div>
//           <label htmlFor="numberOfQuestions" className={labelClasses}>Questions</label>
//           <input
//             type="number"
//             id="numberOfQuestions"
//             name="numberOfQuestions"
//             value={formData.numberOfQuestions}
//             onChange={handleInputChange}
//             className={inputClasses}
//             min="1"
//             max="50"
//             required
//           />
//         </div>
//       </div>

//       <div>
//         <label className={labelClasses}>Question Types</label>
//         <div className="grid grid-cols-2 gap-4">
//           {[
//             { value: 'mcq', label: 'Multiple choice' },
//             { value: 'fillInBlanks', label: 'Fill in the blanks' },
//             { value: 'trueOrFalse', label: 'True/False' },
//             { value: 'openEnded', label: 'Open Ended' }
//           ].map(({ value, label }) => (
//             <label key={value} className="flex items-center">
//               <input
//                 type="checkbox"
//                 value={value}
//                 checked={formData.questionTypes.includes(value)}
//                 onChange={handleQuestionTypeChange}
//                 className="rounded-sm border-[#E5E5E5] text-black focus:ring-0 focus:ring-offset-0"
//               />
//               <span className="ml-2 text-sm text-gray-900">{label}</span>
//             </label>
//           ))}
//         </div>
//       </div>

//       <button
//         type="submit"
//         disabled={isLoading}
//         className={buttonClasses}
//       >
//         {getButtonText()}
//       </button>
//     </form>
//   );
// };

// export default WorksheetForm;

import React, { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { useUser } from '@clerk/clerk-react';

const WorksheetForm = ({ onSubmit, initialData, isLoading, isAuthenticated, onAuthRequired }) => {
  const { user } = useUser();
  const [credits, setCredits] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchCredits = async () => {
    try {
      const response = await fetch(`https://getworksheet-backend.vercel.app/api/credits/${user.id}`);
      const data = await response.json();
      setCredits(data.credits);
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  // Initial credits fetch
  useEffect(() => {
    if (user) {
      fetchCredits();
    }
  }, [user]);
  // Form state management
  const [formData, setFormData] = useState({
    topic: '',
    subject: 'English',
    gradeLevel: '5',
    numberOfQuestions: 5,
    questionTypes: ['mcq']
  });

  // Form validation check
  const isFormComplete = formData.topic.trim() !== '' &&
    formData.subject &&
    formData.gradeLevel &&
    formData.numberOfQuestions &&
    formData.questionTypes.length > 0;

  // Initialize form with provided data
  useEffect(() => {
    if (initialData) {
      setFormData(prevState => ({
        ...prevState,
        ...initialData,
        questionTypes: initialData.questionTypes || ['mcq']
      }));
    }
  }, [initialData]);

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Question type change handler
  const handleQuestionTypeChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => {
      let newTypes = checked
        ? [...prevState.questionTypes, value]
        : prevState.questionTypes.filter(type => type !== value);

      if (newTypes.length === 0) {
        newTypes = ['mcq'];
      }

      posthog.capture(checked ? 'question_type_added' : 'question_type_removed', {
        action: checked ? 'added' : 'removed',
        questionType: value,
        totalTypesSelected: newTypes.length,
        currentTypes: newTypes,
        previousTypes: prevState.questionTypes
      });

      return {
        ...prevState,
        questionTypes: newTypes
      };
    });
  };

  // Form submission handler with auth check
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      onAuthRequired();
      return;
    }
    if (credits === 0) {
      setShowModal(true); // Show the modal if credits are 0
      return;
    }
    setCredits(prevCredits => Math.max(0, prevCredits - 1));
    await onSubmit(formData);

    // await fetchCredits();
  };

  // Base input styling
  const inputBaseClasses = `
    w-full 
    px-4 
    py-3 
    rounded-lg 
    border 
    border-white 
    bg-[#FBFBFB] 
    shadow-[0px_0px_0px_1px_#E5E5E5] 
    focus:outline-none 
    focus:ring-2 
    focus:ring-blue-500
  `;

  // Text input specific styling
  const textInputClasses = `
    ${inputBaseClasses}
    appearance-none
  `;

  // Select input specific styling for iOS compatibility
  const selectClasses = `
    ${inputBaseClasses}
    appearance-none
    bg-[url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%3E%3Cpath%20fill%3D%22%23374151%22%20d%3D%22M3%205h6L6%209z%22%2F%3E%3C%2Fsvg%3E')]
    bg-no-repeat
    bg-[center_right_1rem]
    pr-10
    cursor-pointer
  `;

  // Number input specific styling
  const numberInputClasses = `
    ${inputBaseClasses}
    [&::-webkit-inner-spin-button]:appearance-none
    [&::-webkit-outer-spin-button]:appearance-none
  `;

  const labelClasses = "block text-sm font-medium text-gray-900 mb-2";

  // Button styling with conditional states
  const buttonClasses = `
    w-full 
    py-3 
    rounded-lg 
    font-medium 
    transition-colors 
    ${isLoading
      ? 'opacity-50 cursor-not-allowed'
      : isFormComplete
        ? 'bg-[#6B65E0] text-white hover:bg-[#5952d3]'
        : 'bg-gray-200 text-gray-500 hover:bg-gray-300'
    }
  `;

  // Dynamic button text based on auth and loading state
  const getButtonText = () => {
    if (isLoading) return 'Generating...';
    if (!isAuthenticated) return 'Register to create free worksheets';
    return 'Generate text worksheet';
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Topic Input */}
      <div>
        <label htmlFor="topic" className={labelClasses}>Topic</label>
        <input
          type="text"
          id="topic"
          name="topic"
          value={formData.topic}
          onChange={handleInputChange}
          className={textInputClasses}
          required
          placeholder="e.g., Figures of speech"
        />
      </div>

      {/* Subject Select */}
      <div className="relative">
        <label htmlFor="subject" className={labelClasses}>Subject</label>
        <select
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          className={selectClasses}
          required
        >
          <option value="English">English</option>
          <option value="Mathematics">Mathematics</option>
          <option value="Science">Science</option>
          <option value="History">History</option>
          <option value="Geography">Geography</option>
          <option value="Literature">Literature</option>
          <option value="Physics">Physics</option>
          <option value="Chemistry">Chemistry</option>
          <option value="Biology">Biology</option>
        </select>
      </div>

      {/* Grade and Questions Grid */}
      <div className="grid grid-cols-2 gap-4">
        {/* Grade Select */}
        <div className="relative">
          <label htmlFor="gradeLevel" className={labelClasses}>Grade</label>
          <select
            id="gradeLevel"
            name="gradeLevel"
            value={formData.gradeLevel}
            onChange={handleInputChange}
            className={selectClasses}
            required
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
              <option key={grade} value={grade.toString()}>
                {grade}{['1', '2', '3'].includes(grade.toString()) ? ['st', 'nd', 'rd'][grade - 1] : 'th'}
              </option>
            ))}
          </select>
        </div>

        {/* Questions Number Input */}
        <div>
          <label htmlFor="numberOfQuestions" className={labelClasses}>Questions</label>
          <input
            type="number"
            id="numberOfQuestions"
            name="numberOfQuestions"
            value={formData.numberOfQuestions}
            onChange={handleInputChange}
            className={numberInputClasses}
            min="1"
            max="50"
            required
          />
        </div>
      </div>

      {/* Question Types Checkboxes */}
      <div>
        <label className={labelClasses}>Question Types</label>
        <div className="grid grid-cols-2 gap-4">
          {[
            { value: 'mcq', label: 'Multiple choice' },
            { value: 'fillInBlanks', label: 'Fill in the blanks' },
            { value: 'trueOrFalse', label: 'True/False' },
            { value: 'openEnded', label: 'Open Ended' }
          ].map(({ value, label }) => (
            <label key={value} className="flex items-center">
              <input
                type="checkbox"
                value={value}
                checked={formData.questionTypes.includes(value)}
                onChange={handleQuestionTypeChange}
                className="rounded-sm border-[#E5E5E5] text-black focus:ring-0 focus:ring-offset-0"
              />
              <span className="ml-2 text-sm text-gray-900">{label}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className={buttonClasses}
      >
        {getButtonText()}
      </button>
      {showModal && (
        <div 
        className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setShowModal(false);
          }
        }}
      >
        <div 
          className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full relative transform transition-all duration-300 ease-in-out scale-100 opacity-100"
          onClick={(e) => e.stopPropagation()}
        >
          <button 
            onClick={() => setShowModal(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Buy Credits</h2>
          <p className="mb-6 text-center text-gray-600">
            Unlock more creative possibilities with our credit packages
          </p>
          <div className="space-y-4">
            <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg">
              <span className="font-semibold text-blue-800">25 Credits</span>
              <span className="text-lg font-bold text-blue-600">$3</span>
            </div>
            <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg">
              <span className="font-semibold text-blue-800">50 Credits</span>
              <span className="text-lg font-bold text-blue-600">$5</span>
            </div>
          </div>
          <div className="flex space-x-4 mt-6">
            <a
              href="https://getworksheet-payment.lemonsqueezy.com/buy/c333c1d9-8ee6-4c50-8db5-c937ab70ffdc"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-1 text-center py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors font-semibold"
            >
              Buy Credits
            </a>
            <button
              onClick={() => setShowModal(false)}
              className="flex-1 text-center py-3 rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-300 transition-colors font-semibold"
            >
              Close
            </button>
          </div>
        </div>
      </div>     
      )}
    </form>
  );
};

export default WorksheetForm;
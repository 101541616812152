

// import React, { useState } from 'react';
// import { FileText, Loader2 } from 'lucide-react';
// import { generateColoringImage } from '../services/coloringService';
// import { jsPDF } from 'jspdf';
// import posthog from 'posthog-js';

// const EXAMPLE_PROMPTS = [
//   "A friendly dragon in a magical garden with flowers and mushrooms",
//   "A cute unicorn prancing through a field of rainbow flowers",
//   "A space astronaut floating among planets and stars"
// ];

// const ColoringPageGenerator = ({ isAuthenticated, onAuthRequired }) => {
//   const [prompt, setPrompt] = useState('');
//   const [generatedImage, setGeneratedImage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const isFormComplete = prompt.trim() !== '';

//   const generateButtonClasses = `
//     w-full py-3 rounded-lg font-medium transition-colors
//     ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
//     ${isFormComplete
//       ? 'bg-[#6B65E0] text-white hover:bg-[#5952d3]'
//       : 'bg-gray-200 text-gray-500 hover:bg-gray-300'}
//   `;

//   const downloadButtonClasses = "w-full py-3 rounded-lg font-medium transition-colors bg-[#6B65E0] text-white hover:bg-[#5952d3]";

//   // Handles example prompt selection with analytics
//   const handleExampleClick = (examplePrompt) => {
//     setPrompt(examplePrompt);
    
//     // Track example prompt selection with detailed metadata
//     posthog.capture("coloring_example_prompt_clicked", {
//       prompt: examplePrompt,
//       promptIndex: EXAMPLE_PROMPTS.indexOf(examplePrompt),
//       timestamp: new Date().toISOString(),
//       isAuthenticated: isAuthenticated
//     });

//     if (!isAuthenticated) {
//       onAuthRequired();
//       return;
//     }

//     handleGenerateImage({
//       preventDefault: () => { }
//     });
//   };

//   // Handles image generation with comprehensive analytics tracking
//   const handleGenerateImage = async (e) => {
//     e.preventDefault();
//     if (!prompt.trim()) return;

//     const startTime = Date.now();

//     if (!isAuthenticated) {
//       posthog.capture("coloring_page_generation_auth_required", {
//         prompt: prompt,
//         isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//         timestamp: new Date().toISOString()
//       });
//       onAuthRequired();
//       return;
//     }

//     setIsLoading(true);
//     setError(null);

//     // Track generation attempt
//     posthog.capture("coloring_page_generation_started", {
//       prompt: prompt,
//       isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//       timestamp: new Date().toISOString()
//     });

//     try {
//       const imageUrl = await generateColoringImage(prompt);
//       setGeneratedImage(imageUrl);

//       // Track successful generation with performance metrics
//       posthog.capture("coloring_page_generation_success", {
//         prompt: prompt,
//         isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//         generationTime: Date.now() - startTime,
//         timestamp: new Date().toISOString()
//       });
//     } catch (err) {
//       console.error('Error:', err);
//       setError('Failed to generate image. Please try again.');

//       // Track generation failure with error details
//       posthog.capture("coloring_page_generation_error", {
//         prompt: prompt,
//         error: err.message,
//         isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//         timestamp: new Date().toISOString()
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Handles PDF download with detailed analytics tracking
//   const handleDownloadPDF = async () => {
//     if (!generatedImage) return;

//     if (!isAuthenticated) {
//       posthog.capture("coloring_page_pdf_download_auth_required", {
//         prompt: prompt,
//         timestamp: new Date().toISOString()
//       });
//       onAuthRequired();
//       return;
//     }

//     const downloadStartTime = Date.now();

//     // Track download initiation
//     posthog.capture("coloring_page_pdf_download_started", {
//       prompt: prompt,
//       isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//       timestamp: new Date().toISOString()
//     });

//     try {
//       setIsLoading(true);

//       const img = new Image();
//       await new Promise((resolve, reject) => {
//         img.crossOrigin = 'anonymous';
//         img.onload = resolve;
//         img.onerror = reject;
//         img.src = generatedImage;
//       });

//       const pdf = new jsPDF({
//         orientation: 'portrait',
//         unit: 'mm',
//         format: 'a4'
//       });

//       const pageWidth = 210;
//       const pageHeight = 297;
//       const margin = 10;
//       const maxWidth = pageWidth - (2 * margin);
//       const maxHeight = pageHeight - (2 * margin);

//       let imgWidth = maxWidth;
//       let imgHeight = (img.height * maxWidth) / img.width;

//       if (imgHeight > maxHeight) {
//         imgHeight = maxHeight;
//         imgWidth = (img.width * maxHeight) / img.height;
//       }

//       const x = (pageWidth - imgWidth) / 2;
//       const y = (pageHeight - imgHeight) / 2;

//       pdf.addImage(img, 'PNG', x, y, imgWidth, imgHeight);
//       pdf.setFontSize(10);
//       pdf.setTextColor(128);
//       const text = 'Generated by GetWorksheet.co';
//       const textWidth = pdf.getTextWidth(text);
//       pdf.text(text, (pageWidth - textWidth) / 2, pageHeight - margin);

//       pdf.save(`coloring-page-${Date.now()}.pdf`);

//       // Track successful download with performance metrics
//       posthog.capture("coloring_page_pdf_download_success", {
//         prompt: prompt,
//         isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//         downloadTime: Date.now() - downloadStartTime,
//         timestamp: new Date().toISOString()
//       });
//     } catch (err) {
//       console.error('Error:', err);
//       setError('Failed to generate PDF. Please try again.');

//       // Track download failure with error details
//       posthog.capture("coloring_page_pdf_download_error", {
//         prompt: prompt,
//         error: err.message,
//         isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
//         timestamp: new Date().toISOString()
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getGenerateButtonText = () => {
//     if (isLoading) {
//       return (
//         <div className="flex items-center justify-center">
//           <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4"/>
//           Generating...
//         </div>
//       );
//     }
//     return isAuthenticated ? 'Generate coloring page' : 'Register to create free coloring pages';
//   };

//   return (
//     <div className="space-y-6">
//       <div className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8 mb-8">
//         <form onSubmit={handleGenerateImage} className="space-y-6">
//           <div>
//             <label
//               htmlFor="prompt"
//               className="block text-sm font-medium text-gray-900 mb-2"
//             >
//               Describe your coloring page
//             </label>
//             <textarea
//               id="prompt"
//               value={prompt}
//               onChange={(e) => setPrompt(e.target.value)}
//               placeholder="e.g., a friendly dragon in a magical garden with flowers and mushrooms"
//               className="w-full px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] focus:outline-none focus:ring-2 focus:ring-blue-500"
//               rows="3"
//               disabled={isLoading}
//             />
//           </div>

//           <div className="space-y-2">
//             <h4 className="text-sm font-medium text-gray-900">
//               Not sure? Try these prompts
//             </h4>
//             <div className="space-y-2">
//               {EXAMPLE_PROMPTS.map((examplePrompt, index) => (
//                 <button
//                   key={index}
//                   onClick={() => handleExampleClick(examplePrompt)}
//                   disabled={isLoading}
//                   className="w-full text-left px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] hover:bg-gray-50 transition-colors"
//                 >
//                   {examplePrompt}
//                 </button>
//               ))}
//             </div>
//           </div>

//           <button
//             type="submit"
//             disabled={isLoading || !prompt.trim()}
//             className={generateButtonClasses}
//           >
//             {getGenerateButtonText()}
//           </button>
//         </form>
//       </div>

//       {error && (
//         <div className="bg-red-50 text-red-500 p-4 rounded-lg">
//           {error}
//         </div>
//       )}

//       {generatedImage && !isLoading && (
//         <div className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8">
//           <div className="mb-4">
//             <img
//               src={generatedImage}
//               alt="Generated coloring page"
//               className="w-full h-auto rounded-lg"
//               crossOrigin="anonymous"
//             />
//           </div>
//           <button
//             onClick={handleDownloadPDF}
//             className={downloadButtonClasses}
//           >
//             <div className="flex items-center justify-center">
//               <FileText className="w-4 h-4 mr-2"/>
//               Download PDF
//             </div>
//           </button>
//           <p className="text-xs text-gray-500 text-center mt-2">
//             PDF format is optimized for A4 paper size
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ColoringPageGenerator;

import React, { useState,useEffect } from 'react';
import { FileText, Loader2 } from 'lucide-react';
import { generateColoringImage } from '../services/coloringService';
import { uploadToCloudinary } from '../config/api';
import { jsPDF } from 'jspdf';
import { useUser } from '@clerk/clerk-react';
import posthog from 'posthog-js';

// Predefined example prompts that users can choose from
const EXAMPLE_PROMPTS = [
  "A friendly dragon in a magical garden with flowers and mushrooms",
  "A cute unicorn prancing through a field of rainbow flowers",
  "A space astronaut floating among planets and stars"
];

const ColoringPageGenerator = ({ isAuthenticated, onAuthRequired }) => {
  // State management for the component
  const [prompt, setPrompt] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [credits, setCredits] = useState(null);
  const {user} = useUser();

  const fetchCredits = async () => {
    try {
      const response = await fetch(`https://getworksheet-backend.vercel.app/api/credits/${user.id}`);
      const data = await response.json();
      setCredits(data.credits);
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  // Initial credits fetch
  useEffect(() => {
    if (user) {
      fetchCredits();
    }
  }, [user]);

  useEffect(() => {
    const cachedColoringPage = localStorage.getItem('coloringPageData');
    const cachedPrompt = localStorage.getItem('coloringPagePrompt');
    
    if (cachedColoringPage && cachedPrompt) {
      try {
        const parsedImage = JSON.parse(cachedColoringPage);
        setGeneratedImage(parsedImage);
        setPrompt(cachedPrompt);
      } catch (error) {
        console.error('Error parsing cached coloring page:', error);
        localStorage.removeItem('coloringPageData');
        localStorage.removeItem('coloringPagePrompt');
      }
    }
  }, []);

  // Form validation
  const isFormComplete = prompt.trim() !== '';

  // Dynamic button styling based on component state
  const generateButtonClasses = `
    w-full py-3 rounded-lg font-medium transition-colors
    ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
    ${isFormComplete
      ? 'bg-[#6B65E0] text-white hover:bg-[#5952d3]'
      : 'bg-gray-200 text-gray-500 hover:bg-gray-300'}
  `;

  const downloadButtonClasses = "w-full py-3 rounded-lg font-medium transition-colors bg-[#6B65E0] text-white hover:bg-[#5952d3]";

  // Function to handle saving PDFs to Cloudinary
  const saveToCloudinary = async (pdfBlob, prompt) => {
    try {
      // Create a title from the prompt, limiting length to avoid issues
      const title = `coloring-page-${prompt.substring(0, 30)}`;
      const result = await uploadToCloudinary(pdfBlob, title);
      
      // Track successful upload
      posthog.capture("coloring_page_cloudinary_upload_success", {
        prompt,
        url: result.url,
        timestamp: new Date().toISOString()
      });
      
      console.log('Coloring page saved to Cloudinary:', result);
    } catch (error) {
      // Track failed upload
      posthog.capture("coloring_page_cloudinary_upload_error", {
        prompt,
        error: error.message,
        timestamp: new Date().toISOString()
      });
      
      console.error('Error saving to Cloudinary:', error);
    }
  };

  // Handle clicks on example prompts
  const handleExampleClick = (examplePrompt) => {
    localStorage.removeItem('coloringPageData');
    localStorage.removeItem('coloringPagePrompt');

    setPrompt(examplePrompt);
    
    // Track which example prompts are being used
    posthog.capture("coloring_example_prompt_clicked", {
      prompt: examplePrompt,
      promptIndex: EXAMPLE_PROMPTS.indexOf(examplePrompt),
      timestamp: new Date().toISOString(),
      isAuthenticated
    });

    // Check authentication before proceeding
    if (!isAuthenticated) {
      onAuthRequired();
      return;
    }

    handleGenerateImage({
      preventDefault: () => { }
    });
  };

  // Handle image generation
  const handleGenerateImage = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    const startTime = Date.now();

    // Handle unauthenticated users
    if (!isAuthenticated) {
      posthog.capture("coloring_page_generation_auth_required", {
        prompt,
        isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
        timestamp: new Date().toISOString()
      });
      onAuthRequired();
      return;
    }

    if (credits === 0) {
      setShowModal(true); // Show the modal if credits are 0
      return;
    }

    localStorage.removeItem('coloringPageData');
    localStorage.removeItem('coloringPagePrompt');

    setIsLoading(true);
    setError(null);

    // Track generation attempt
    posthog.capture("coloring_page_generation_started", {
      prompt,
      isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
      timestamp: new Date().toISOString()
    });

    setCredits(prevCredits => Math.max(0, prevCredits - 1));

    try {
      const imageUrl = await generateColoringImage(prompt, user.id);
      setGeneratedImage(imageUrl);

      localStorage.setItem('coloringPageData', JSON.stringify(imageUrl));
      localStorage.setItem('coloringPagePrompt', prompt);

      // await fetchCredits();

      // Track successful generation
      posthog.capture("coloring_page_generation_success", {
        prompt,
        isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
        generationTime: Date.now() - startTime,
        timestamp: new Date().toISOString()
      });
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate image. Please try again.');

      // Track generation failure
      posthog.capture("coloring_page_generation_error", {
        prompt,
        error: err.message,
        isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
        timestamp: new Date().toISOString()
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle PDF download and Cloudinary upload
  const handleDownloadPDF = async () => {
    if (!generatedImage) return;

    if (!isAuthenticated) {
      posthog.capture("coloring_page_pdf_download_auth_required", {
        prompt,
        timestamp: new Date().toISOString()
      });
      onAuthRequired();
      return;
    }

    const downloadStartTime = Date.now();

    posthog.capture("coloring_page_pdf_download_started", {
      prompt,
      isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
      timestamp: new Date().toISOString()
    });

    try {
      setIsLoading(true);

      // Load the image
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.crossOrigin = 'anonymous';
        img.onload = resolve;
        img.onerror = reject;
        img.src = generatedImage;
      });

      // Create PDF with A4 dimensions
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      // Calculate dimensions for PDF
      const pageWidth = 210;
      const pageHeight = 297;
      const margin = 10;
      const maxWidth = pageWidth - (2 * margin);
      const maxHeight = pageHeight - (2 * margin);

      // Calculate image dimensions to fit page
      let imgWidth = maxWidth;
      let imgHeight = (img.height * maxWidth) / img.width;

      if (imgHeight > maxHeight) {
        imgHeight = maxHeight;
        imgWidth = (img.width * maxHeight) / img.height;
      }

      // Center image on page
      const x = (pageWidth - imgWidth) / 2;
      const y = (pageHeight - imgHeight) / 2;

      // Add image to PDF
      pdf.addImage(img, 'PNG', x, y, imgWidth, imgHeight);

      // Add watermark
      pdf.setFontSize(10);
      pdf.setTextColor(128);
      const text = 'Generated by GetWorksheet.co';
      const textWidth = pdf.getTextWidth(text);
      pdf.text(text, (pageWidth - textWidth) / 2, pageHeight - margin);

      // Get PDF as blob for Cloudinary
      const pdfBlob = pdf.output('blob');
      
      // Save PDF for user
      pdf.save(`coloring-page-${Date.now()}.pdf`);

      // Upload to Cloudinary in background
      saveToCloudinary(pdfBlob, prompt).catch(console.error);

      // Track successful download
      posthog.capture("coloring_page_pdf_download_success", {
        prompt,
        isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
        downloadTime: Date.now() - downloadStartTime,
        timestamp: new Date().toISOString()
      });
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate PDF. Please try again.');

      // Track download failure
      posthog.capture("coloring_page_pdf_download_error", {
        prompt,
        error: err.message,
        isExamplePrompt: EXAMPLE_PROMPTS.includes(prompt),
        timestamp: new Date().toISOString()
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Dynamic button text based on state
  const getGenerateButtonText = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center">
          <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4"/>
          Generating...
        </div>
      );
    }
    return isAuthenticated ? 'Generate coloring page' : 'Register to create free coloring pages';
  };

  return (
    <div className="space-y-6">
      <div className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8 mb-8">
        <form onSubmit={handleGenerateImage} className="space-y-6">
          <div>
            <label
              htmlFor="prompt"
              className="block text-sm font-medium text-gray-900 mb-2"
            >
              Describe your coloring page
            </label>
            <textarea
              id="prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="e.g., a friendly dragon in a magical garden with flowers and mushrooms"
              className="w-full px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
              disabled={isLoading}
            />
          </div>

          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-900">
              Not sure? Try these prompts
            </h4>
            <div className="space-y-2">
              {EXAMPLE_PROMPTS.map((examplePrompt, index) => (
                <button
                  key={index}
                  onClick={() => handleExampleClick(examplePrompt)}
                  disabled={isLoading}
                  className="w-full text-left px-4 py-3 rounded-lg border border-white bg-[#FBFBFB] shadow-[0px_0px_0px_1px_#E5E5E5] hover:bg-gray-50 transition-colors"
                >
                  {examplePrompt}
                </button>
              ))}
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading || !prompt.trim()}
            className={generateButtonClasses}
          >
            {getGenerateButtonText()}
          </button>
        </form>
      </div>

      {showModal && (
        <div 
        className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setShowModal(false);
          }
        }}
      >
        <div 
          className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full relative transform transition-all duration-300 ease-in-out scale-100 opacity-100"
          onClick={(e) => e.stopPropagation()}
        >
          <button 
            onClick={() => setShowModal(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Buy Credits</h2>
          <p className="mb-6 text-center text-gray-600">
            Unlock more creative possibilities with our credit packages
          </p>
          <div className="space-y-4">
            <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg">
              <span className="font-semibold text-blue-800">25 Credits</span>
              <span className="text-lg font-bold text-blue-600">$3</span>
            </div>
            <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg">
              <span className="font-semibold text-blue-800">50 Credits</span>
              <span className="text-lg font-bold text-blue-600">$5</span>
            </div>  
          </div>
          <div className="flex space-x-4 mt-6">
            <a
              href="https://getworksheet-payment.lemonsqueezy.com/buy/c333c1d9-8ee6-4c50-8db5-c937ab70ffdc"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-1 text-center py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors font-semibold"
            >
              Buy Credits
            </a>
            <button
              onClick={() => setShowModal(false)}
              className="flex-1 text-center py-3 rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-300 transition-colors font-semibold"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      )}

      {error && (
        <div className="bg-red-50 text-red-500 p-4 rounded-lg">
          {error}
        </div>
      )}

      {generatedImage && !isLoading && (
        <div className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8">
          <div className="mb-4">
            <img
              src={generatedImage}
              alt="Generated coloring page"
              className="w-full h-auto rounded-lg"
              crossOrigin="anonymous"
            />
          </div>
          <button
            onClick={handleDownloadPDF}
            className={downloadButtonClasses}
          >
            <div className="flex items-center justify-center">
              <FileText className="w-4 h-4 mr-2"/>
              Download PDF
            </div>
          </button>
          <p className="text-xs text-gray-500 text-center mt-2">
            PDF format is optimized for A4 paper size
          </p>
        </div>
      )}
    </div>
  );
};

export default ColoringPageGenerator;